import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          No, not yet.
        </h1>
        <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Shelling_of_Mainila"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
         >
          But check out the Shelling of Mainila
        </a>
      </header>
    </div>
  );
}

export default App;
